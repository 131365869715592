import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { Tiles } from '@rebass/layout';

import categories from '@data/cats.json';
import Section from '@/components/section';
import Button from '@/components/button';
import { Category, CategoryIcon } from '@/components/categories-grid';

const featuredCategories = categories.filter(category => (
  ['entertainment', 'gaming', 'travel', 'house-and-home', 'groceries', 'clothing-and-accessories'].includes(category.slug)
));

const Title = styled.h1`
  font-weight: 600;
  margin: 0 auto 1.5em;
`;

const Categories = styled(Tiles)`
  margin-bottom: 2em;

  a {
    transition: transform 250ms;
    will-change: transform;

    &:hover {
      transform: translateY(-0.25em);
    }
  }
`;

const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.maxContentWidth};
  text-align: center;
`;

const BrowseMore = () => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const t = useTranslations('browseMore');

  const handleClick = () => {
    setIsLoading(true);
  };

  return (
    <Section background={theme.colors.primary} inverted>
      <InnerContainer>
        <Title>{t('title')}</Title>
        <Categories columns={[2, 2, 3, 6]} mb="2em">
          {featuredCategories.map(category => (
            <Category
              background="white"
              category={category}
              IconComponent={<CategoryIcon category={category} size={96} />}
              key={category.slug} />
          ))}
        </Categories>
        <Link href="/buy" passHref>

          <Button
            accent
            isLoading={isLoading}
            onClick={handleClick}>
            {t('browseAll')}
          </Button>

        </Link>
      </InnerContainer>
    </Section>
  );
};

export default BrowseMore;
