import { GameController } from '@styled-icons/entypo/GameController';
import { Travel } from '@styled-icons/zondicons/Travel';
import { Restaurant } from '@styled-icons/ionicons-sharp/Restaurant';
import { Female } from '@styled-icons/ionicons-sharp/Female';
import { Male } from '@styled-icons/ionicons-sharp/Male';
import { Wine } from '@styled-icons/ionicons-sharp/Wine';
import { Ticket } from '@styled-icons/ionicons-sharp/Ticket';
import { FlightTakeoff } from '@styled-icons/material-rounded/FlightTakeoff';
import { Hotel } from '@styled-icons/material-rounded/Hotel';
import { SportsFootball } from '@styled-icons/material-rounded/SportsFootball';
import { Coffee } from '@styled-icons/material-rounded/Coffee';
import { SportsGolf } from '@styled-icons/material-rounded/SportsGolf';
import { ShoppingBasket } from '@styled-icons/material-rounded/ShoppingBasket';
import { Home } from '@styled-icons/material-rounded/Home';
import { Uber } from '@styled-icons/fa-brands/Uber';
import { PaintRoller } from '@styled-icons/fa-solid/PaintRoller';
import { BabyCarriage } from '@styled-icons/fa-solid/BabyCarriage';
import { Closet } from '@styled-icons/boxicons-regular/Closet';
import { Spa } from '@styled-icons/boxicons-regular/Spa';
import { Asterisk } from '@styled-icons/bootstrap/Asterisk';
import { Phone } from '@styled-icons/fluentui-system-filled/Phone';
import { Globe } from '@styled-icons/fa-solid/Globe';
import { Pin } from '@styled-icons/material-outlined/Pin';
import { Tv } from '@styled-icons/ionicons-solid/Tv';
import { PackageIcon } from '@styled-icons/boxicons-solid/PackageIcon';
import { SimCard } from '@styled-icons/material-twotone/SimCard';
import categories from '@data/cats.json';

export const iconForCategory = category => {
  const slugToIconMap = {
    coffee: Coffee,
    flights: FlightTakeoff,
    gaming: GameController,
    hotels: Hotel,
    'maternity-and-toys': BabyCarriage,
    giftcard: Asterisk,
    'gifts-for-her': Female,
    'gifts-for-him': Male,
    groceries: ShoppingBasket,
    'health-and-spa': Spa,
    'house-and-home': Home,
    entertainment: Ticket,
    sports: SportsFootball,
    golf: SportsGolf,
    renovations: PaintRoller,
    travel: Travel,
    'restaurants-and-food': Restaurant,
    'wine-and-cocktails': Wine,
    uber: Uber,
    'clothing-and-accessories': Closet,
    topup: Phone,
    data: Globe,
    pin: Pin,
    digitaltv: Tv,
    bundle: PackageIcon,
    esim: SimCard
  };

  return slugToIconMap[category.slug] || null;
};

export const colorForCategory = category => {
  const slugToColorMap = {
    'clothing-and-accessories': '#EF5350',
    coffee: '#3F51B5',
    entertainment: '#9C27B0',
    flights: '#7E57C2',
    'gifts-for-her': '#EC407A',
    gaming: '#3F51B5',
    golf: '#00ACC1',
    hotels: '#2196F3',
    'house-and-home': '#009688',
    'maternity-and-toys': '#C0CA33',
    groceries: '#FFB300',
    renovations: '#F57C00',
    'restaurants-and-food': '#FF5722',
    sports: '#7CB342',
    travel: '#607D8B',
    uber: '#424242',
    'wine-and-cocktails': '#B71C1C',
    esim: '#6236FF'
  };

  return slugToColorMap[category.slug] || '#333';
};

export const labelForSlug = slug => {
  const labels = categories.reduce((acc, curr) => {
    acc[curr.slug] = curr.name;
    return acc;
  }, {
    bundle: 'Bundles',
    data: 'Data',
    digitaltv: 'Digital TV',
    giftcard: 'All giftcards',
    pin: 'Phone Vouchers/PIN',
    topup: 'Prepaid Phones',
    esim: 'eSIMs'
  });

  return labels[slug] || 'All giftcards';
};
